import { getRequest, postRequest } from '..';

export function getOrganizationAuth(id) {
  return getRequest(`/dashboard/account/otp/auth/${id}`);
}

export function requestOTP(emailAddress) {
  return postRequest(
    '/dashboard/account/otp',
    {
      emailAddress,
    },
    false,
  );
}

export function validateOTP(emailAddress, totpCode) {
  return postRequest(
    '/dashboard/account/otp/auth',
    {
      emailAddress,
      totpCode,
    },
    false,
  );
}
