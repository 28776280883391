export function createVModelHandlers(...propNames) {
  return propNames
    .reduce((accumulator, currentValue) => ({
      ...accumulator,
      [`${currentValue}Handler`]: {
        get() {
          return this[currentValue];
        },
        set(value) {
          this.$emit(`update:${currentValue}`, value);
        },
      },
    }), {});
}
