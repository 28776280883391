<template>
  <div
    class="alert"
    :class="classes"
    role="alert"
  >
    <span class="alert-icon">
      <i :class="getIcon(icon)" />
    </span>
    <span class="alert-text">
      {{ icon && "&nbsp;" }}
      <slot />
    </span>
    <button
      v-if="dismissible"
      type="button"
      class="btn-close d-flex justify-content-center align-items-center"
      data-bs-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true" class="text-lg font-weight-bold">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "MaterialAlert",
  props: {
    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: "",
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return [
        { 'text-white': !this.outline },
        { [`alert-${this.color}`]: this.color && !this.outline },
        { [`border border-2 border-${this.color} text-${this.color}`]: this.color && this.outline },
        { 'alert-dismissible fade show': this.dismissible },
      ];
    },
  },

  methods: {
    getIcon: (icon) => (icon ? icon : null),
  },
};
</script>
